export const utmCookieNames = [
  'utm_medium',
  'utm_campaign',
  'utm_source',
  'utm_offer',
]

export const getUtmFromCookies = cookies => {
  const existUtmInCookies = utmCookieNames.some(utmPram => cookies[utmPram])
  if (!existUtmInCookies) {
    return {
      utm_campaign: 'autocred',
      utm_medium: 'site',
      utm_offer: '',
      utm_source: 'organico',
    }
  }

  return utmCookieNames.reduce((acc, param) => {
    if (cookies[param]) {
      acc[param] = cookies[param]
    }

    return acc
  }, {})
}
