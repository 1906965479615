import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import shouldSendEvent from './helpers/shouldSendEvent'
import usePageViewsFactory from './helpers/usePageViewsFactory'

const usePageViews = googleAnalyticsId => {
  const sendView = location =>
    window.gtag('config', googleAnalyticsId, { page_path: location.pathname })

  usePageViewsFactory('gtag', sendView)
}

const event = ({ action, category }) => {
  if (shouldSendEvent('gtag', window)) {
    window.gtag('event', action, { event_category: category })
  }
}

const Init = ({ analyticsId, optimizeId }) => (
  <Helmet>
    {/* dataLayer init */}
    <script>window.dataLayer = window.dataLayer || []</script>

    {/* Anti-flicker style */}
    <style>{'.async-hide { opacity: 0 !important }'}</style>

    {/* Anti-flicker script */}
    <script>
      {`
      (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000,
      {'${optimizeId}':true});
      `}
    </script>

    {/* Google site tag (gtag.js) - Google Analytics */}
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
    />
    <script>
      {`
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${analyticsId}', { 'optimize_id': '${optimizeId}', 'send_page_view': false });
      `}
    </script>
  </Helmet>
)

Init.propTypes = {
  analyticsId: PropTypes.string.isRequired,
  optimizeId: PropTypes.string.isRequired,
}

export default { event, Init, usePageViews }
