import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { utmCookieNames } from '../utils/getUtmFromCookies'
import useSearchParam from './useSearchParam'

function getCookieDomain(hostname) {
  const match = hostname.match(/(?:[^.]+\.)?([^.]+\.[^.]+)$/)
  return match ? `.${match[1]}` : `.${hostname}`
}

const useUTMS = () => {
  const searchParams = useSearchParam()
  const [, setCookie, removeCookie] = useCookies(utmCookieNames)

  useEffect(() => {
    const hasUrlParamsWithUtm = utmCookieNames.some(param =>
      searchParams.get(param),
    )

    if (hasUrlParamsWithUtm) {
      utmCookieNames.forEach(param => removeCookie(param))
    }

    utmCookieNames.forEach(param => {
      const value = searchParams.get(param)
      if (!value) return
      setCookie(param, value, { domain: getCookieDomain(window.location.host) })
    })

    const offerValue = searchParams.get('offer')

    if (offerValue) {
      setCookie('utm_offer', offerValue, {
        domain: getCookieDomain(window.location.host),
      })
    }
  }, [searchParams, removeCookie, setCookie])
}

export default useUTMS
